<template>

  <div id="infinite-list" style="margin-bottom: 100px;">

    <Competition
        v-show="!highlights"
        v-for="(l,index) in competitions"
        v-bind:key="getLeagueKey(l,index)"
        v-bind:competition="l"
        v-bind:is_visible="true"
        v-bind:upcoming="upcoming"
        v-bind:today="today"
        v-bind:esport="esport"
        v-bind:highlights="highlights"
        v-bind:live="live"
        v-bind:date="date">
    </Competition>
    <div v-if="fixtures && fixtures.length > 0">
      <Highlight
          v-for="(l,index) in fixtures"
          v-bind:key="getLeagueKey(l,index)"
          v-bind:fixture="l">
      </Highlight>
    </div>
    <div class="no-fixtures-message" v-else>
      No fixtures available.
    </div>

    <Shimmer v-show="is_busy"></Shimmer>

    <scroll-loader v-if="page<4" v-show="highlights || upcoming || today" :loader-method="loadOnScroll"
                   :loader-disable="is_busy">
      <div></div>
    </scroll-loader>
    <div v-show="page>3" v-if="!is_busy" class="text-center  hover-pointer text-success" @click="activateLoadOnScroll">
      Load More
    </div>
    <div class="text-light text-center" v-show="no_results">
      No Results found
    </div>

  </div>

</template>

<script>
const Competition = () => import('./Competition.vue')
import Highlight from './Highlight'
import Vue from 'vue'
import ScrollLoader from 'vue-scroll-loader'
import Shimmer from '../components/shimmer/Shimmer.vue'

import axios from "@/services/api";
import mqtt from 'mqtt';

Vue.use(ScrollLoader)


export default {
  name: "Games",
  mounted: function () {

    var vm = this;
    this.resetFilters();
    this.no_results = false;
    // var place_holder_matches = this.$store.state.placeHolderMatches;

    // vm.fixtures = place_holder_matches;
    // this.highlights = false;
    // this.upcoming = false;
    // this.today = false;

    //  vm.getHighlight()
    vm.market_outcome_headers = this.$store.state.market_outcome;

    this.is_busy = true;
    vm.visible_leagues = [];

    // keep a list of opened/expanded leagues
    this.EventBus.$on('event:leagues:show', function (id) {

      var exists = false;
      vm.jQuery.each(vm.visible_leagues, function (k, v) {

        if (parseInt(v) === parseInt(id)) {

          exists = true;

        }

      })

      if (!exists) {

        vm.visible_leagues.push(parseInt(id));

      }

    });

    this.initMqtt(this.sport_id);

    this.$nextTick(function () {
      // console.log(this.$vnode.tag)
      vm.autoRefreshUI(this.$vnode.tag);

    });

    //setInterval(function (){

    if (vm.searchable) {

      if (vm.search.length > 0) {

        vm.getSearch();

      } else {

        vm.is_busy = false;

      }

    } else if (vm.live) {

      vm.getLive();

    } else if (vm.esport) {

      vm.getEsport();

    } else if (vm.highlights) {

      vm.is_busy = false;
      vm.getHighlight();

    } else if (vm.today) {

      //vm.getTodayFixtures();
      vm.getToday();

    } else if (vm.upcoming) {

      vm.getUpcoming();

    } else if (vm.leo) {

      vm.live = true;
      vm.getAllLive();

    } else {

      vm.getCompetitions();

    }

    //},1000 * 30);

  },
  methods: {
    activateLoadOnScroll: function () {
      // this.load_on_scroll = true;
      this.per_page = 100;
      this.loadOnScroll();
    },
    resetGames: function () {

      var vm = this;

      if (vm.highlights) {

        vm.page = 0;
        vm.last_page = 0;
        vm.remaining_records = 0;
        vm.highlightsRawData = [];
        vm.getHighlight();
        return
      }

      if (vm.upcoming) {

        vm.upcoming_page = 0;
        vm.upcoming_last_page = 0;
        vm.upcoming_remaining_records = 0;
        vm.upcomingRawData = [];
        vm.getUpcoming();
        return
      }

      if (vm.today) {

        vm.today_page = 0;
        vm.today_last_page = 0;
        vm.today_remaining_records = 0;
        vm.todayRawData = [];
        vm.getNewToday();
        return
      }

    },
    loadOnScroll: function () {

      var vm = this;

      if (vm.highlights && vm.load_on_scroll) {

        vm.getHighlight();
        console.log("trying to get highlights")
      } else if (vm.upcoming && vm.load_on_scroll) {

        vm.getUpcoming();
      } else if (vm.today && vm.load_on_scroll) {

        vm.getToday();
      }


    },
    registerPoll: function () {

      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id == 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_betstop = 'topic/producer-3/sport-' + sport_id + '/status/+/market-' + market_id;

      if (this.live) {

        topic_betstop = 'topic/producer-1/sport-' + sport_id + '/status/+/market-' + market_id;

      }

      this.poll(topic_betstop);

    },
    initMqtt: function () {

      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;

      var vm = this;

      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id === 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_betstop = 'topic/producer-3/sport-' + sport_id + '/status/+/market-' + market_id;
      var topic_others = 'topic/producer-3/sport-' + sport_id + '/betstop/+';
      //var topic_match = 'topic/producer-3/sport-'+sport_id+'/match/+';
      var topic_match = 'topic/producer-3/sport-' + sport_id + '/status/+';
      // var topic_producer = 'topic/producer-3';

      if (this.live) {

        topic_others = 'topic/producer-1/sport-' + sport_id + '/betstop/+';
        topic_betstop = 'topic/producer-1/sport-' + sport_id + '/status/+/market-' + market_id;
        topic_match = 'topic/producer-1/sport-' + sport_id + '/status/+';
        //topic_producer = 'topic/producer-1';

      }

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {

        client.subscribe(topic_betstop, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic_betstop);

          }
        })

        client.subscribe(topic_others, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic_others);

          }
        })

        client.subscribe(topic_match, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic_match);

          }
        })

        var topic2 = 'topic/producer';
        client.subscribe(topic2, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic2);

          }
        })
      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

    },
    getKey: function (fixture, index) {

      if (Array.isArray(fixture)) {

        var currentFixture = fixture[0];

      } else {

        currentFixture = fixture

      }

      var prefix = currentFixture === undefined || currentFixture.match_id == undefined ? index : currentFixture.match_id;
      return Math.random().toString(10).replace('0.', 'fixture-id-' + prefix + '-');

    },
    getLeagueKey: function (league, index) {

      var prefix = league === undefined || league.competition_id == undefined ? index : league.competition_id;
      return Math.random().toString(10).replace('0.', 'competition-id-' + prefix + '-');

    },
    getCompetitions: function () {

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_TOP_LEAGUES;

      if (this.live) {

        endpoint = process.env.VUE_APP_URL_LIVE_TOP_LEAGUES;

      }

      if (this.esport) {

        endpoint = process.env.VUE_APP_URL_ESPORT_TOP_LEAGUES;

      }

      var path = endpoint.replace("{sport_id}", parseInt(vm.sport_id) > 0 ? vm.sport_id : 1);
      path = path.replace("{count}", 0)

      var games_filter = {};

      if (this.date.length > 0) {

        games_filter.date = this.date;

      }

      if (parseInt(this.upcoming) > 0) {

        games_filter.upcoming = this.upcoming;
        games_filter.highlights = ''

      }

      games_filter.hour = vm.hour;
      games_filter.hours = vm.hour;

      axios.post(path, JSON.stringify(games_filter))
          .then(res => {

            var leagues = res.data.message;

            if (vm.highlight || vm.highlight) {

              vm.jQuery.each(leagues, function (k, v) {
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;

                var exist = false;

                // check if league exists in current leagues
                vm.jQuery.each(vm.leagues, function (kk, vv) {

                  if (vv.competition_id === v.competition_id) {

                    exist = true;
                  }

                })

                if (!exist) {

                  vm.leagues.push(v)

                }

              });

            } else {

              vm.leagues = leagues;
              vm.jQuery.each(vm.leagues, function (k, v) {
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;
                vm.leagues[k] = v;
              });

            }

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.getGames();
            vm.autoRefreshUI(vm.$vnode.tag);

          })
          .catch(error => {

            console.log(error)

          })

    },
    getFixture: function (competitions) {

      var vm = this;

      var path = process.env.VUE_APP_URL_FIXTURES_COMPETITIONS;

      var payload = {
        highlights: 1,
        upcoming: 0,
        hours: this.hour,
        country_code: "",
        date: vm.date,
        keyword: "",
        per_page: 100,
        competitions: competitions
      };

      if (this.highlights) {

        path = process.env.VUE_APP_URL_HIGHLIGHTS;

      } else if (this.today) {

        path = process.env.VUE_APP_URL_TODAYS_COMPETITIONS;

      } else if (this.live) {

        path = process.env.VUE_APP_URL_LIVE_ODDS;

      }

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{competition_id}", 0);
      path = path.replace("{market_id}", vm.market_id);
      path = path.replace("{page}", 1);


      if (this.date.length > 0) {

        payload.date = this.date;

      }

      if (parseInt(this.upcoming) > 0) {

        payload.upcoming = this.upcoming;
        payload.highlights = ''

      }

      axios.post(path, JSON.stringify(payload))
          .then(res => {

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = res.data.message;

            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })
            vm.competitions = vm.leagues;
            vm.autoRefreshUI(vm.$vnode.tag);

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    getTodayFixtures: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_TODAY;

      path = path.replace("{sport_id}", 1);

      axios.post(path, JSON.stringify({
        hours: vm.hour,
        market_id: vm.market_id,
      }))
          .then(res => {

            var games = res.data.message;
            vm.leagues = games.competitions;

            // set defaults for each received leagues
            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;
            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures from res.data.message.data
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })

            vm.competitions = vm.leagues;

            vm.getCompetitions();

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    getAllLive: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_LIVE_ALL;

      path = path.replace("{sport_id}", 0);

      axios.post(path, JSON.stringify({}))
          .then(res => {

            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;
            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })

            vm.competitions = vm.leagues;

            vm.getCompetitions();

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getSearch: function () {

      var vm = this;
      vm.leagues = [];
      vm.competitions = [];
      vm.is_busy = true;
      vm.fixtures = [];

      var path = process.env.VUE_APP_URL_SEARCH;

      path = path.replace("{sport_id}", 1);

      axios.post(path, JSON.stringify({search: vm.search}))
          .then(res => {
            vm.is_busy = false;
            vm.busy = false;
            this.is_busy = false;
            vm.loading = '';
            var games = res.data.message;
            vm.leagues = games.competitions;
            //console.log(`Search --> ${JSON.stringify(vm.leagues)}`);

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.visible_leagues = [];

            var results = games.data;
            // console.log("search results: " + results);
            if (results == null) {
              this.no_results = true;
            } else {
              this.no_results = false;
            }
            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
              console.log(this.is_busy);
            })

            vm.competitions = vm.leagues;
            vm.highlightsData = results;
            if (results.length == 0) {
              this.no_results = true;
            }

          })
          .catch(err => {

            vm.busy = false;
            vm.is_busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getHighlight1: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_HIGHLIGHTS_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        console.log('Current page ' + vm.page + ' but reached end of page ');
        return;
      }

      if (vm.is_busy) {

        console.log('Current page ' + vm.page + ' but is busy ');
        return;

      }

      console.log('Current page ' + vm.page);
      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy', true);

      var top_leagues = new Object();

      axios.post(path, JSON.stringify({
        page: vm.page,
        hours: vm.hour,
        per_page: vm.per_page,
        competition_id: this.competition_id,
      }))
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy', false);

            var highlightsRawData = res.data.message.data;
            vm.last_page = res.data.message.last_page;
            vm.remaining_records = res.data.message.remaining_records;

            if (parseInt(vm.page) === 1) {

              vm.highlightsRawData = [];
            }

            if (highlightsRawData.length === 0) {

              vm.last_page = vm.page - 1;

            }

            console.log("highlightsRawData.length === " + highlightsRawData.length);


            vm.jQuery.each(highlightsRawData, function (k, v) {

              vm.highlightsRawData.push(v);

            });

            vm.filterHighlight()

            //vm.market_outcome_headers = res.data.message.headers;
            // top leagues

            vm.jQuery.each(vm.highlightsRawData, function (k, v) {

              var tournament = v.tournament;
              var competition_id = v.competition_id;
              var country = v.country;

              var ob = {
                country_name: country,
                competition_id: competition_id,
                competition: country + '/' + tournament
              }

              top_leagues["c_" + competition_id] = ob

            });

            var t_l = [];
            vm.jQuery.each(top_leagues, function (k, v) {
              t_l.push(v)
            });

            vm.top_leagues = t_l;
            vm.$store.dispatch("setLeague", vm.top_leagues);

          })
          .catch(err => {

            vm.EventBus.$emit('event:busy', false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getUpcoming1: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_UPCOMING_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {

        vm.is_busy = false;
        console.log('Current page ' + vm.page + ' but reached end of page ');
        return;

      }

      if (vm.is_busy) {

        console.log('Current page ' + vm.page + ' but is busy ');
        return;

      }

      console.log('Current page ' + vm.page);
      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy', true);

      var top_leagues = new Object();

      axios.post(path, JSON.stringify({
        page: vm.page,
        hours: vm.hour,
        per_page: vm.per_page,
        competition_id: this.competition_id,
      }))
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy', false);

            var highlightsRawData = res.data.message.data;
            vm.last_page = res.data.message.last_page;
            vm.remaining_records = res.data.message.remaining_records;

            if (parseInt(vm.page) === 1) {

              vm.highlightsRawData = [];
            }

            if (highlightsRawData.length === 0) {

              vm.last_page = vm.page - 1;

            }

            console.log("highlightsRawData.length === " + highlightsRawData.length);


            vm.jQuery.each(highlightsRawData, function (k, v) {

              vm.highlightsRawData.push(v);

            });

            vm.filterHighlight()

            //vm.market_outcome_headers = res.data.message.headers;
            // top leagues

            vm.jQuery.each(vm.highlightsRawData, function (k, v) {

              var tournament = v.tournament;
              var competition_id = v.competition_id;
              var country = v.country;

              var ob = {
                country_name: country,
                competition_id: competition_id,
                competition: country + '/' + tournament
              }

              top_leagues["c_" + competition_id] = ob

            });

            var t_l = [];
            vm.jQuery.each(top_leagues, function (k, v) {
              t_l.push(v)
            });

            vm.top_leagues = t_l;
            vm.$store.dispatch("setLeague", vm.top_leagues);

          })
          .catch(err => {

            vm.EventBus.$emit('event:busy', false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getUpcoming: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_UPCOMING_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.upcoming_page) > 0 && parseInt(vm.upcoming_page) > parseInt(vm.upcoming_last_page)) {

        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {

        return;

      }

      vm.upcoming_page = parseInt(vm.upcoming_page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy', true);

      axios.post(path, JSON.stringify({
        page: vm.upcoming_page,
        hours: vm.hour,
        per_page: vm.per_page,
        competition_id: this.competition_id,
        country_code: this.country_code,
      }))
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy', false);

            var highlightsRawData = res.data.message.data;
            vm.upcoming_last_page = res.data.message.last_page;
            vm.upcoming_remaining_records = res.data.message.remaining_records;

            if (parseInt(vm.upcoming_page) === 1) {

              vm.upcomingRawData = [];
            }

            if (highlightsRawData.length === 0) {

              vm.upcming_last_page = vm.upcoming_page - 1;

            }

            vm.jQuery.each(highlightsRawData, function (k, v) {

              // check if match_id exists
              var match_id = v.match_id;
              var exists = false;

              vm.jQuery.each(vm.upcomingRawData, function (kk, vv) {

                if (parseInt(vv.match_id) === parseInt(match_id)) {

                  exists = true;
                }
              });

              if (!exists) {

                vm.upcomingRawData.push(v);

              }

            });

            vm.fixtures = vm.upcomingRawData;
            this.$store.dispatch("setCommitPlaceHolderMatches", vm.fixtures);

          })
          .catch(err => {

            vm.EventBus.$emit('event:busy', false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getToday: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_TODAY_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.today_page) > 0 && parseInt(vm.today_page) > parseInt(vm.today_last_page)) {

        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {

        return;

      }

      vm.today_page = parseInt(vm.today_page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy', true);

      axios.post(path, JSON.stringify({
        page: vm.today_page,
        hours: vm.hour,
        per_page: vm.per_page,
        competition_id: this.competition_id,
        country_code: this.country_code,
      }))
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy', false);

            var highlightsRawData = res.data.message.data;
            vm.today_last_page = res.data.message.last_page;
            vm.today_remaining_records = res.data.message.remaining_records;

            if (parseInt(vm.today_page) === 1) {

              vm.todayRawData = [];
            }

            if (highlightsRawData.length === 0) {

              vm.today_last_page = vm.today_page - 1;

            }

            vm.jQuery.each(highlightsRawData, function (k, v) {

              // check if match_id exists
              var match_id = v.match_id;
              var exists = false;

              vm.jQuery.each(vm.todayRawData, function (kk, vv) {

                if (parseInt(vv.match_id) === parseInt(match_id)) {

                  exists = true;
                }
              });

              if (!exists) {

                vm.todayRawData.push(v);

              }

            });

            vm.fixtures = vm.todayRawData;
            this.$store.dispatch("setCommitPlaceHolderMatches", vm.fixtures);

          })
          .catch(err => {

            vm.EventBus.$emit('event:busy', false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getNewToday: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_TODAY_V2_URL;

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.today_page) > 0 && parseInt(vm.today_page) > parseInt(vm.today_last_page)) {

        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {

        return;

      }

      vm.today_page = 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy', true);

      axios.post(path, JSON.stringify({
        page: vm.today_page,
        hours: vm.hour,
        per_page: vm.per_page,
        competition_id: this.competition_id,
        country_code: this.country_code,
      }))
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy', false);

            var highlightsRawData = res.data.message.data;
            vm.today_last_page = res.data.message.last_page;
            vm.today_remaining_records = res.data.message.remaining_records;

            vm.todayRawData = [];

            if (highlightsRawData.length === 0) {

              vm.today_last_page = vm.today_page - 1;

            }

            vm.jQuery.each(highlightsRawData, function (k, v) {

              vm.todayRawData.push(v);

            });

            vm.fixtures = vm.todayRawData;

            this.$store.dispatch("setCommitPlaceHolderMatches", vm.fixtures);

          })
          .catch(err => {

            vm.EventBus.$emit('event:busy', false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getHighlight: function () {

      var vm = this;
      this.is_loading = true;

      var path = process.env.VUE_APP_URL_HIGHLIGHTS_V2_URL;
      console.log(this.$store.state.sport_id)
      path = path.replace("{sport_id}", this.$store.state.sport_id);
      path = path.replace("{market_id}", vm.market_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {

        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {

        return;

      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit('event:busy', true);

      var dat = {
        page: vm.page,
        hours: vm.hour,
        per_page: vm.per_page,
        competition_id: this.competition_id,
        country_code: this.country_code,
      };

      console.log('getHighlight ' + JSON.stringify(dat));

      axios.post(path, JSON.stringify(dat))
          .then(res => {

            vm.is_busy = false;
            vm.EventBus.$emit('event:busy', false);

            var highlightsRawData = res.data.message.data;
            vm.last_page = res.data.message.last_page;
            vm.remaining_records = res.data.message.remaining_records;

            if (parseInt(vm.page) === 1) {

              vm.highlightsRawData = [];
            }

            //console.log('vm.highlightsRawData  ==> '+JSON.stringify(vm.highlightsRawData ));

            if (highlightsRawData.length === 0) {

              vm.last_page = vm.today_page - 1;

            }

            vm.jQuery.each(highlightsRawData, function (k, v) {

              // check if match_id exists
              var match_id = v.match_id;
              var exists = false;

              vm.jQuery.each(vm.highlightsRawData, function (kk, vv) {

                if (parseInt(vv.match_id) === parseInt(match_id)) {

                  exists = true;
                }
              });

              if (!exists) {
                vm.highlightsRawData.push(v);

              }

            });

            vm.fixtures = vm.highlightsRawData;
            // this.$store.dispatch("setCommitPlaceHolderMatches",vm.fixtures);
            vm.autoRefreshUI(vm.$vnode.tag);
            this.is_loading = false;
            // console.log("fixture to store: " + this.$store.state.placeHolderMatches);

          })
          .catch(err => {

            vm.EventBus.$emit('event:busy', false);

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }
            this.is_loading = false;

          })
    },

    filterHighlight: function () {

      var vm = this;

      vm.highlightsData = [];

      if (parseInt(vm.competition_id) < 1) {

        vm.highlightsData = vm.highlightsRawData;
        return;
      }

      vm.jQuery.each(vm.highlightsRawData, function (k, v) {

        if (parseInt(vm.competition_id) === parseInt(v.competition_id)) {

          vm.highlightsData.push(v);
        }

      });

    },

    getEsport: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_ESPORT_SPORT;

      path = path.replace("{sport_id}", 1);

      axios.post(path, JSON.stringify({}))
          .then(res => {

            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })

            vm.competitions = vm.leagues;

            vm.getCompetitions();

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getLive: function () {

      console.log('getLive')
      var vm = this;

      var path = process.env.VUE_APP_URL_LIVE_SPORT;
      //var path = process.env.VUE_APP_URL_LIVE;

      path = path.replace("{sport_id}", 1);

      axios.post(path, JSON.stringify({}))
          .then(res => {

            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {

              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {

                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {

                  fx.push(vv);
                  vm.EventBus.$emit('event:leagues:show', v.competition_id);

                }

              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;

            })

            vm.competitions = vm.leagues;

            vm.getCompetitions();

          })
          .catch(err => {

            vm.busy = false;

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getGames: function () {

      var vm = this;

      var c = 10;
      var b = 0;

      var competitions = [];

      this.jQuery.each(vm.leagues, function (k, v) {

        b++;
        var competition_id = v.competition_id;
        // get fixtures

        if (b < c && v.fixtures.length === 0) {

          competitions.push(competition_id);

        }

      })

      vm.getFixture(competitions.join(','));

    },

    reloadUI: function () {

      this.autoRefreshUI(this.$vnode.tag);

    },

  },
  computed: {
    loadingDistance: function () {

      return 50

    },
    market_outcome: function () {

      return this.market_outcome_headers;
    },
    games: function () {

      return this.$store.state.games
    },
    allLoaded: function () {

      return this.$store.state.games.current_page > 0 && this.$store.state.games.current_page === this.$store.state.games.last_page
    },
    busy1: function () {

      return this.$store.state.busy
    },
    country_code: function () {

      return this.$store.state.country_code;
    },
    sport_id: function () {

      return this.$store.state.sport_id;
    },
    sport_name: function () {

      return this.$store.state.sport_name;
    },
    market_id: function () {

      return this.$store.state.market_id;
    },
    competition_id: function () {

      return this.$store.state.competition_id;
    },
    hour: function () {

      return this.$store.state.hour;
    },
    profile: function () {

      return this.getProfile();

    },
  },
  components: {
    Competition,
    Shimmer,
    Highlight,
  },
  data: function () {
    return {
      leagues: [],
      top_leagues: [],
      competitions: [],
      highlightsData: [],
      highlightsRawData: [],
      loading: [],
      visible: [],
      no_results: false,
      busy: [],
      is_busy: false,
      visible_leagues: [],
      mqttClient: false,
      market_outcome_headers: [],
      per_page: 15,
      page: 0,
      last_page: 0,
      remaining_records: 0,
      load_on_scroll: true,
      today_page: 0,
      today_last_page: 0,
      today_remaining_records: 0,
      todayRawData: [],
      upcoming_page: 0,
      upcoming_last_page: 0,
      upcoming_remaining_records: 0,
      upcomingRawData: [],
      fixtures: [],
      is_loading: false,

    }
  },
  props: {
    date: {
      required: false,
      default: ""
    },
    searchable: {
      required: false,
      default: false,
      type: Boolean,
    },
    search: {
      required: false,
      default: "",
      type: String,
    },
    upcoming: {
      required: false,
      default: false
    },
    highlights: {
      required: false,
      default: false
    },
    live: {
      required: false,
      default: false
    },
    today: {
      required: false,
      default: false
    },
    leo: {
      required: false,
      default: false
    },
    sport: {
      required: false,
      default: 1
    },
    esport: {
      required: false,
      default: false
    },
  },
  watch: {
    sport_id: function () {

      this.is_busy = false;
      this.resetGames();
      this.initMqtt();

    },
    market_id: function (newValue) {

      console.log('market_id ==> ' + newValue)
      this.is_busy = false;
      this.resetGames();
    },
    hour: function (newValue, oldValue) {

      console.log('Hour =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);

      if (parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      if (this.highlights) {

        this.per_page = 20;
        this.page = 0;
        this.last_page = 3;
        this.remaining_records = 0;
        this.highlightsRawData = [];
        this.getHighlight();
      } else {
        this.getCompetitions();
      }
    },
    search: function (newValue, oldValue) {

      console.log('search =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);

      if (newValue !== oldValue && newValue.length > 0) {

        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {

      console.log('date newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);

      this.getCompetitions();
    },
    competition_id: function () {
      this.is_busy = false;
      this.resetGames();

    },
    today: function (newValue) {

      console.log('today ==> ' + newValue);

      if (newValue) {

        this.is_busy = false;
        this.fixtures = this.todayRawData;
        this.getToday();
      }

    },
    upcoming: function (newValue) {

      console.log('upcoming ==> ' + newValue);

      if (newValue) {

        this.is_busy = false;
        this.fixtures = this.upcomingRawData
        this.getUpcoming();
      }
    },
    highlights: function (newValue) {

      if (newValue) {

        this.is_busy = false;
        this.fixtures = this.highlightsRawData
        this.getHighlight();
      }
    },
    country_code: function () {

      this.is_busy = false;
      this.resetGames();

    },
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }
  }
}

</script>

<style scoped>
.no-fixtures-message {
  text-align: center;
  padding: 20px;
  background-color: #1E2A3A;
  color: white;
  border-radius: 5px;
}
</style>